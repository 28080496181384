<template>
  <div class="d-flex justify-content-center">
    <div class="col-sm-12 col-md-6 w-100">
      <b-nav class="navbar navbar-light shadow mb-1 d-flex justify-content-between bd-highlight flex-wrap">
        <div class="bd-highlight">
          <b-button variant="danger" @click="() => $router.go(-1)"> กลับ </b-button>
        </div>
        <div>{{ this.$route.params.name }}</div>
        <div><b-button v-b-toggle.reply_form variant="success"> ตอบกลับ </b-button></div>
      </b-nav>
      <b-collapse id="reply_form">
        <b-form ref="replyForm">
          <b-form-textarea v-model="sender" placeholder="รายระเอียด" rows="3" />
          <b-form-file ref="refInputEl" v-model="changImage" @change="handleFileUpload" :hidden="true" accept="image/*" plain multiple />
          <div v-if="chatImage.length != 0" class="row mt-1">
            <div class="col-4" v-for="(img, index) in chatImage" :key="index">
              <div class="text-center">
                <b-img width="75" height="50" :src="imageConvertUrl(img)" variant="light-primary" />
                <br />
                <b-button
                  @click="
                    () => {
                      chatImage.splice(index, 1);
                    }
                  "
                  class="btn-icon"
                  variant="flat-danger"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>
          </div>
          <!-- submit and reset -->
          <b-col offset-md="4" class="mb-1 mt-1">
            <b-button variant="outline-info" class="mr-75" @click="$refs.refInputEl.$el.click()">
              <feather-icon icon="CameraIcon" />
            </b-button>
            <b-button @click.prevent="replyForm()" type="submit" variant="primary" class="mr-1"> ส่ง </b-button>
            <b-button type="reset" variant="outline-secondary"> รีเซ็ต </b-button>
          </b-col>
        </b-form>
      </b-collapse>
      <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <b-card>
          <div class="text-center mb-1">
            <span v-if="this.$store.getters.getStates[0].status === 'open'">
              <b-badge variant="danger">
                <small class="text-white">เเจ้ง</small>
              </b-badge>
            </span>
            <span v-else-if="this.$store.getters.getStates[0].status === 'in progress'">
              <b-badge variant="info">
                <small class="text-white">ดำเนินการ</small>
              </b-badge>
            </span>
            <span v-else-if="this.$store.getters.getStates[0].status === 'resolved'">
              <b-badge variant="success">
                <small class="text-white">เสร็จเเล้ว</small>
              </b-badge>
            </span>
          </div>
          <!-- sub -->
          <div v-for="(item, index) in this.items_sub.slice().reverse()" :key="`${item.ticketId}-${item.registerDate}`">
            <!-- {{ index }} -->
            <app-timeline>
              <!-- FINANCIAL REPORT -->
              <app-timeline-item variant="primary">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
                  <span>{{ item.description != 'txt_msg_description' ? item.description : '' }}</span>
                  <span>{{ item.title ? item.title : '' }}</span>
                  <!-- <h6>{{ item }}</h6> -->
                </div>

                <div v-show="item.urlImage.length != 0">
                  <b-button v-b-toggle="`report${index}`" size="sm" variant="outline-primary"> รูปภาพ </b-button>
                  <b-collapse :id="`report${index}`">
                    <div v-for="(img, index) in item.urlImage" :key="index">
                      <b-img class="p-1" width="250" :src="img" />
                    </div>
                    <!-- <b-img class="p-1" width="250" :src="item.urlImage"></b-img> -->
                  </b-collapse>
                </div>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <small class="text-muted">{{ item.sender ? item.sender : item.assignee }}</small>
                  <small class="text-muted">{{ formatTofromNow(item.registerDate) }}</small>
                </div>
              </app-timeline-item>
            </app-timeline>
          </div>

          <!-- main -->
          <div v-for="(item, index) in this.items_main" :key="`${item.ticketId}-${item.registerDate}`">
            <app-timeline>
              <!-- FINANCIAL REPORT -->
              <app-timeline-item variant="primary">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
                  <span>{{ item.title ? item.title : '' }}</span>
                  <div>
                    <span v-if="item.status === 'open'">
                      <b-badge variant="danger">
                        <small class="text-white">เเจ้ง</small>
                      </b-badge>
                    </span>
                    <span v-else-if="item.status === 'in progress'">
                      <b-badge variant="info">
                        <small class="text-white">ดำเนินการ</small>
                      </b-badge>
                    </span>
                    <span v-else-if="item.status === 'resolved'">
                      <b-badge variant="success">
                        <small class="text-white">เสร็จเเล้ว</small>
                      </b-badge>
                    </span>
                  </div>
                </div>

                <div v-show="item.description != 'txt_msg_description'" class="mb-1">
                  <p class="p-0 m-0">{{ item.description }}</p>
                </div>
                <div v-show="item.urlImage.length != 0">
                  <b-button v-b-toggle="`report_main${index}`" size="sm" variant="outline-primary"> รูปภาพ </b-button>
                  <b-collapse :id="`report_main${index}`">
                    <div v-for="(img, index) in item.urlImage" :key="index">
                      <b-img class="p-1" width="250" :src="img" />
                    </div>
                  </b-collapse>
                </div>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <small class="text-muted">{{ item.assignee ? item.assignee : '' }}</small>
                  <small class="text-muted">{{ formatTofromNow(item.registerDate) }}</small>
                </div>
              </app-timeline-item>
            </app-timeline>
          </div>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
  import Ripple from 'vue-ripple-directive';

  import API from '@/connect/config';
  import axios from 'axios';
  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');
  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  import socket from '../socket';

  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        API: API,
        title: '',
        items_sub: [],
        items_main: [],
        // ? loading
        playLoad: true,
        // ? sen
        sender: '',
        changImage: [],
        chatImage: [],
      };
    },
    async created() {
      // console.log(await this.$store.getters.getStates);
      if ((await this.$store.getters.getStates.length) === 0) {
        this.$router.go(-1);
      }
      this.getData();
      socket.on('sentMessage', (message) => {
        this.getData();
      });
    },

    methods: {
      replyForm() {
        if (this.chatImage.length === 0 && !this.sender) {
          return;
        }
        let { userId, uId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        let { ticketId } = this.$route.params;
        let { username } = JSON.parse(localStorage.getItem('profile_visitor'));

        const _data = new FormData();
        _data.append('userId', userId);
        _data.append('uId', uId);
        _data.append('ticketId', ticketId);
        _data.append('sender', username);
        _data.append('description', this.sender);
        if (this.chatImage.length != 0) {
          // _data.append('chatImage', this.chatImage);
          this.chatImage.forEach((el) => {
            _data.append('chatImage', el);
          });
        }

        axios
          .post(`${API}chat`, _data)
          .then(async (el) => {
            console.log(el);

            socket.emit('sentMessage', ticketId);
            this.$nextTick(() => {
              this.$refs.replyForm.reset();
              this.changImage = [];
              this.chatImage = [];
            });
            // this.getData();
          })
          .catch((err) => {
            console.log(err.data.message);
          });
      },
      async handleFileUpload(event) {
        const file = event.target.files;
        if (file.length == 0) return;

        Array.from(file).forEach((file) => {
          // Create an image element
          const img = new Image();
          const reader = new FileReader();
          reader.onload = (e) => {
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
          img.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas size
            const MAX_WIDTH = 600; // Set your desired max width
            const MAX_HEIGHT = 600; // Set your desired max height
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const resizedFile = new File([blob], file.name, { type: 'image/jpeg' }, 0.7);
                // console.log(resizedFile)
                this.chatImage.push(resizedFile);
              },
              'image/jpeg',
              0.7
            );
          };
        });
      },
      imageConvertUrl(image) {
        if (image) {
          const url = window.URL.createObjectURL(image);
          return url;
        }
      },
      async getImage(value) {
        const res = await this.$store.dispatch('getImageURL', value);

        return res;
      },
      async getData() {
        let { userId, uId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        let { username } = JSON.parse(localStorage.getItem('profile_visitor'));
        let { ticketId, type } = this.$route.params;
        let data1 = [];
        let data2 = [];
        this.$store.dispatch('getAll', `${API}ticket/${userId}?uId=${uId}&type=${type}`).then(async (res) => {
          data1 = res.filter((el) => el.ticketId === ticketId);
          this.$store.dispatch('getAll', `${API}chat/${userId}?ticketId=${ticketId}`).then(async (res) => {
            const formattedData1Date = dayjs(data1[0].registerDate).format('DD-MMM-YYYY MM');
            const promises = res.map(async (item) => {
              let image = [];
              const formattedTicketDate = dayjs(item.registerDate).format('DD-MMM-YYYY MM');
              const shouldAddToData1 = formattedTicketDate === formattedData1Date && item.description === 'txt_msg_description' && item.sender === data1[0].assignee;
              if (item.ticketId === ticketId) {
                if (item.chatImage.length !== 0) {
                  item.chatImage.forEach(async (el) => {
                    image.push(await this.$store.dispatch('getImageURL', el));
                  });
                }
                item.urlImage = image;
                if (shouldAddToData1) {
                  data1.push(item);
                } else {
                  data2.push(item);
                }
              }
            });
            await Promise.all(promises);
            // merged.push(...data1, ...data2);
            this.items_sub = data2;

            if (this.$store.getters.getStates[0].status === 'open') {
              this.items_main = this.$store.getters.getStates;
            } else {
              this.items_sub.splice(0, 1);
              this.items_main = this.$store.getters.getStates.map((el) => {
                return {
                  ...el,
                  assignee: username,
                };
              });
            }

            this.playLoad = false;
          });
        });
      },
      formatTofromNow(date) {
        // เวลาที่อัพเดท
        return dayjs().locale('th').to(dayjs(date));
      },
    },
  };
</script>

<style></style>
